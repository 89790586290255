import "./card.styles.css";

const Card = (props) => {
  return (
    <div className="card">
      <a href={props.url} target="_blank" rel="noreferrer">
        <img src={props.image} alt={props.alt} />
        <h1 className="card-title">{props.title}</h1>
        <p className="card-description">{props.description}</p>
      </a>
    </div>
  );
};

export default Card;
