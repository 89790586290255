import Card from "./components/card/card.component";
import taskManager from "../src/assets/task-manager_180x180.png";
import cs from "../src/assets/coming-soon_180x180.jpg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <h1 className="main-title">Web App Demo Portfolio</h1>
      <div className="card-container">
        <Card
          url="https://monsters.webappdemo.dev"
          image="https://robohash.org/4?set=set2&size=180x180"
          alt="Monster Image"
          title="Monster Rolodex"
          description="site for monsters rolodex"
        />
        <Card
          url="https://task.webappdemo.dev"
          image={taskManager}
          alt="Task Manager Image"
          title="Task Manager"
          description="Todo App to Manage Tasks"
        />
        <Card url="#" image={cs} alt="TBA" title="TBA" description="TBA" />
      </div>
    </div>
  );
}

export default App;
